import React from 'react'
import { useLocalState } from './Layout'

const PodcastCard = ({ coverImage, podcast }) => {
	const {
		itunes: { image },
	} = podcast,
  { setCurrentEpisode, playEpisode } = useLocalState(),
  imgSrc = image?.attrs?.href ? image.attrs.href : coverImage

	return (
		<article
      className="group relative cursor-pointer"
			onClick={() => {
        setCurrentEpisode({ ...podcast, autoplay: true })
        playEpisode()
      }}
      tabIndex="0"
		>
      <div className="h-0" style={{ paddingBottom: `100%` }}>
        <img src={imgSrc} alt="" className="group-hover:opacity-80 group-focus:opacity-80 transition-opacity duration-300" />

      </div>
			<div className="content mt-1.5">
				<div className="flex relative">
					<p className="text-xs group-hover:opacity-0 group-focus:opacity-0 transition-opacity duration-300 ease-in-out ">
						<span className="font-sans uppercase">
							S{podcast?.itunes?.season} E
							{podcast?.itunes?.episode}
						</span>
						{` `}
						<span className="font-serif text-match italic">
							{podcast?.itunes?.title}
						</span>
					</p>
					<p className="absolute top-0 left-0 font-sans text-xs opacity-0 group-hover:opacity-100 group-focus:opacity-100 transition-opacity duration-300 ease-in-out ellipsis">
						{podcast?.itunes?.summary}
					</p>
				</div>
			</div>
		</article>
	)
}

export default PodcastCard
