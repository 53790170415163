import React from 'react'
import Seo from '../components/Seo'
import Footer from '../components/Footer'
import { useStaticQuery, graphql } from 'gatsby'
import PodcastCard from '../components/PodcastCard'
import { useTransitionClasses } from "../hooks/useTransitionClasses"

const PODCAST_QUERY = graphql`
	query PodcastQuery {
		allFeedClerestoryPodsMeta {
			nodes {
				image {
          url
				}
			}
		}
		allFeedClerestoryPods {
			nodes {
        enclosure {
          type
          url
          length
        }
        guid
        id
        itunes {
          author
          duration
          episode
          season
          summary
          title
          image {
            attrs {
              href
            }
          }
        }
        link
        pubDate
			}
		}
	}
`

const PodcastPage = ({ transitionStatus }) => {
	const {
		allFeedClerestoryPods: { nodes },
		allFeedClerestoryPodsMeta: { nodes: meta },
	} = useStaticQuery(PODCAST_QUERY),
  transitionClasses = useTransitionClasses({ transitionStatus }),
  sortedPodcasts = nodes.slice(0).sort((a, b) => parseInt(b.itunes.episode, 10) - parseInt(a.itunes.episode, 10))

	return (
		<div className={transitionClasses}>
			<Seo
        title="Podcast"
        image={{
          gatsbyImageData: {
            width: 3000,
            height: 3000,
            images: {
              fallback: {
                src: meta[0].image.url
              }
            }
          }
        }}
      />
      <div className="large-container pt-48 mb-48">
        <div className="w-full max-w-xxs sm:max-w-xs md:max-w-xl pt-16 mx-auto grid md:grid-cols-2 items-start gap-y-20 md:gap-x-36 md:gap-y-48">
					{sortedPodcasts.map((podcast, index, array) => {
            return (
              <PodcastCard
                key={index}
                podcast={podcast}
                coverImage={meta[0].image.url}
              />
            )
          })}
				</div>
			</div>
      <Footer />
		</div>
	)
}

export default PodcastPage
